import Fade from "@mui/material/Fade";
import Modal from "@mui/material/Modal";
import React from "react";
import ViewPDF from "../Form/CreditData/ViewPDF";
import ExcelViewer from "components/DocumentsSection/ExcelViewer";
import { DocumentExtensionTypes } from "components/CMS/shared/CMSConstants";

const FileViewModal = ({ open, onClose, blobUrl, fileType, password }) => {
  document.addEventListener("contextmenu", (e) => e.preventDefault());
  const getFileViewComponent = (fileType) => {
    switch (fileType) {
      case DocumentExtensionTypes.PDF:
        return <ViewPDF fileUrl={blobUrl} passwordValue={password} />;
      case DocumentExtensionTypes.JPEG:
      case DocumentExtensionTypes.PNG:
      case DocumentExtensionTypes.JPG:
        return <img src={blobUrl} alt="Display Image" />;
      case DocumentExtensionTypes.XLSX:
        return <ExcelViewer fileUrl={blobUrl} />;
      default:
        return (
          <span className="text-red-500">
            This file is not supported. Please contact the tech team.
          </span>
        );
    }
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="flex justify-center items-center"
      open={open}
      onClose={onClose}
      closeAfterTransition
    >
      <Fade in={open}>
        <div
          className="p-12 rounded bg-white h-screen overflow-scroll"
          style={{ width: "80%", height: "100vh" }}
        >
          <div className="flex justify-end cursor-pointer">
            <div
              className="text-xl"
              onClick={() => {
                onClose();
              }}
            >
              &times;
            </div>
          </div>
          <div className="flex justify-center items-center">
            {getFileViewComponent(fileType?.toLowerCase())}
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default FileViewModal;
