import React, { useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewPDF = ({ fileUrl, rotate = 0, scale = 1.5, passwordValue = "" }) => {
  const [numPages, setNumPages] = useState(null);
  const [password, setPassword] = useState(passwordValue);
  const [isFileProtected, setIsFileProtected] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Document
      file={{
        url: fileUrl,
      }}
      onContextMenu={(e) => e.preventDefault()}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={isFileProtected && password ? "Invalid password" : "Loading PDF..."}
      onPassword={(callback) => {
        setIsFileProtected(true);
        if (!password) {
          const result = prompt("Please enter the password");
          setPassword(result);
          callback(result);
        }
        if (password) {
          callback(password);
        }
      }}
    >
      {Array.apply(null, Array(numPages))
        .map((x, i) => i + 1)
        .map((page, index) => (
          <Page
            pageNumber={page}
            scale={scale}
            rotate={rotate}
            key={`view-pdf-${index}`}
          />
        ))}
    </Document>
  );
};

export default ViewPDF;
