import axios from "axios";
import { errorMessages } from "lib/utils/constants";
import { useState } from "react";

const useUploadService = ({
  uploadFile = async () => {},
  input,
  isMultipleUpload = false,
}) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [viewFileModal, setViewFileModal] = useState(false);
  const [blobUrl, setBlobUrl] = useState("");
  const [fileType, setFileType] = useState("");

  const handleFileUpload = async (file) => {
    setError(null);
    setLoading(true);
    input.onFocus();
    const fileObject = { name: file.name, size: file.size };
    if (isMultipleUpload) {
      const isAlreadyUploaded = (element) => element.name === file.name;
      if ((input.value || []).some(isAlreadyUploaded)) {
        setError("Already Uploaded");
        setLoading(false);
        return;
      }
    }
    const url = await uploadFile(file, input?.value[0]);
    setLoading(false);
    isMultipleUpload
      ? input.onChange(
          (input.value || [])
            .filter((doc) => doc.url)
            .concat([
              {
                ...fileObject,
                url,
              },
            ])
        )
      : input.onChange([
          {
            ...input.value[0],
            ...fileObject,
            url,
          },
        ]);
    input.onBlur();
  };

  const handleMultipleUploadReplace = async (newFile, fileToBeReplaced) => {
    setError("");
    setLoading(true);
    input.onFocus();
    const fileObject = {
      name: newFile.name,
      size: newFile.size,
      id: fileToBeReplaced?.id,
    };
    let url;
    const isAlreadyUploaded = (element) => element.name === newFile.name;
    if ((input.value || []).some(isAlreadyUploaded)) {
      setError("Already Uploaded");
      setLoading(false);
      return;
    } else {
      url = await uploadFile(newFile);
    }
    setLoading(false);
    const newFiles = (input.value || []).map((f) =>
      f.name !== fileToBeReplaced.name ? f : { ...newFile, ...fileObject, url }
    );
    input.onChange(newFiles);
    input.onBlur();
  };

  const handleView = async ({
    forBankAccount = false,
    slug,
    password,
    url = "",
    fileName = "",
  }) => {
    setLoading(true);
    const config = forBankAccount
      ? {
          method: "GET",
          url: `/api/downloadDoc?path=bank_account/${slug}/${input.value[0].documentName}/viewDocument`,
          responseType: "blob",
        }
      : {
          method: "GET",
          url: `/api/downloadDoc?path=documents/download?url=${
            url || input.value[0].url
          }`,
          responseType: "blob",
        };
    try {
      const downloadResponse = await axios(config);
      if (
        downloadResponse &&
        downloadResponse.data &&
        downloadResponse.data.size > 0
      ) {
        const file = new Blob([downloadResponse.data], {
          type: "application/pdf",
        });
        const blobUrlForView = URL.createObjectURL(file);
        setLoading(false);
        setBlobUrl(blobUrlForView);
        setPassword(password);
        setViewFileModal(true);
        setFileType((fileName || input.value[0].name).split(".").pop());
      } else {
        setLoading(false);
        setError(errorMessages.FILE_VIEW_FAILED);
      }
    } catch (ex) {
      console.log("file view error =", ex);
      setLoading(false);
      setError(errorMessages.FILE_VIEW_FAILED);
    }
  };

  const handleErrors = async (e) => {
    setError(e.message);
  };

  const closeViewModal = () => {
    setViewFileModal(false);
    setBlobUrl("");
    setFileType("");
  };

  return {
    error,
    loading,
    password,
    viewFileModal,
    blobUrl,
    fileType,
    handleFileUpload,
    handleMultipleUploadReplace,
    handleView,
    closeViewModal,
    handleErrors,
  };
};

export default useUploadService;
