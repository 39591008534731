const { ManualCSVUploadTypes } = require("../../lib/utils/constants");

export const manualUploadData = [
  {
    sectionTitle: "Document Segregation for RCU and FI",
    fieldName: "rcufi",
    fieldLabel: "Upload app ids list for RCU & FI",
    uploadType: ManualCSVUploadTypes.RCUFI,
  },
  {
    sectionTitle: "Disbursement Migration",
    fieldName: "disbursementMigration",
    fieldLabel: "Upload Disbursement Migration File",
    uploadType: ManualCSVUploadTypes.DISBURSEMENT,
  },
  {
    sectionTitle: "Bank account status upload",
    fieldName: "bankStatusDoc",
    fieldLabel: "Upload Bank account status CSV",
    uploadType: ManualCSVUploadTypes.BANKSTATUS,
  },
  {
    sectionTitle: "Refund status upload",
    fieldName: "refundDoc",
    fieldLabel: "Upload refund CSV",
    uploadType: ManualCSVUploadTypes.REFUND,
  },
  {
    sectionTitle: "Cashback status upload",
    fieldName: "cashbackDoc",
    fieldLabel: "Upload cashback status CSV",
    uploadType: ManualCSVUploadTypes.CASHBACK,
  },
  {
    sectionTitle: "Vervent Transaction History Upload",
    fieldName: "verventDoc",
    fieldLabel: "Upload Vervent Transaction data",
    uploadType: ManualCSVUploadTypes.VERVENTTXN,
  },
  {
    sectionTitle: "Warehouse Details Upload",
    fieldName: "warehouseDoc",
    fieldLabel: "Upload Warehouse Details",
    uploadType: ManualCSVUploadTypes.WAREHOUSE,
  },
  {
    sectionTitle: "Flow Template Details Upload",
    fieldName: "flowTemplateDoc",
    fieldLabel: "Upload Flow Template Details",
    uploadType: ManualCSVUploadTypes.FLOW_TEMPLATE,
  },
  {
    sectionTitle: "PA Details Upload",
    fieldName: "paDetailsDoc",
    fieldLabel: "Upload PA Details",
    uploadType: ManualCSVUploadTypes.PA_DETAILS,
  },
  {
    sectionTitle: "Loan Fee Details Upload",
    fieldName: "loanFeeDetailsDoc",
    fieldLabel: "Upload Loan Fee Details",
    uploadType: ManualCSVUploadTypes.LOAN_FEE_DETAILS,
  },
  {
    sectionTitle: "Negative Information Notice Details Upload",
    fieldName: "negativeInformationNoticeDoc",
    fieldLabel: "Upload Negative Information Notice Details",
    uploadType: ManualCSVUploadTypes.NEGATIVE_INFORMATION_NOTICE,
  },
  {
    sectionTitle: "Quacker Contact Upload",
    fieldName: "quackerContactUploadDoc",
    fieldLabel: "Upload Quacker Contact Details",
    uploadType: ManualCSVUploadTypes.QUACKER_CONTACT,
  },
  {
    sectionTitle: "Vervent Loan Daily Upload",
    fieldName: "verventLoanDailyReport",
    fieldLabel: "Upload Vervent Loan Daily Report",
    uploadType: ManualCSVUploadTypes.VERVENT_LOAN_DAILY_REPORT,
  },
  {
    sectionTitle: "Loan Closure Date Upload",
    fieldName: "loanClosureDetailsUpload",
    fieldLabel: "Upload Loan Closure Details",
    uploadType: ManualCSVUploadTypes.LOAN_CLOSURE_DETAILS,
  },
];

export const uploadUrls = {
  RCUFI: "/api/route?path=portal/segregateDocumentForRCUAndFI",
  DISBURSEMENT: "/api/route?path=disbursement_portal/data-migration",
  BANKSTATUS: "/api/route?path=bank_account/stage-update",
  REFUND: "/api/route?path=application_portal/refund",
  CASHBACK: "/api/route?path=application_portal/cashback",
  VERVENTTXN: "/api/upload?path=external-data/vervent-transaction",
  WAREHOUSE: "/api/upload?path=external-data/upload-ware-house-details",
  FLOW_TEMPLATE: "/api/upload?path=external-data/upload-flow-template-details",
  PA_DETAILS: "/api/upload?path=external-data/upload-pa-details",
  LOAN_FEE_DETAILS: "/api/upload?path=external-data/upload-loan-fees-details",
  NEGATIVE_INFORMATION_NOTICE:
    "/api/upload?path=external-data/upload-emi-bounce-details",
  QUACKER_CONTACT: "/api/upload?path=external-data/jerry-gen/contact",
  VERVENT_LOAN_DAILY_REPORT:
    "/api/upload?path=external-data/upload-vervent-loan-daily-report",
  LOAN_CLOSURE_DETAILS:
    "/api/upload?path=external-data/upload-loan-closure-date",
};
