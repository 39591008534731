import React, { useState, useEffect } from "react";
import XLSX from "xlsx";

const ExcelViewer = ({ fileUrl }) => {
  const [excelData, setExcelData] = useState(null);

  useEffect(() => {
    const fetchExcelData = async () => {
      try {
        const response = await fetch(fileUrl);
        const arrayBuffer = await response.arrayBuffer();
        const data = new Uint8Array(arrayBuffer);

        const workbook = XLSX.read(data, { type: "array" });

        // Assuming the first sheet is the one you want to display
        const worksheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        setExcelData(jsonData);
      } catch (error) {
        console.error("Error fetching or parsing the XLSX file:", error);
      }
    };

    fetchExcelData();
  }, [fileUrl]);

  if (!excelData) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <table className="table-auto border-collapse border border-gray-500">
        <thead>
          <tr>
            {excelData[0].map((header, index) => (
              <th
                key={index}
                className={`border border-gray-500 px-4 py-2 text-left ${
                  index === 0 ? "max-w-[200px] break-all" : ""
                }`}
              >
                {header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {excelData.slice(1).map((row, index) => (
            <tr
              key={index}
              className={index % 2 === 0 ? "bg-gray-100" : "bg-white"}
            >
              {row.map((cell, index) => (
                <td
                  key={index}
                  className={`border border-gray-500 px-4 py-2 ${
                    index === 0 ? "w-[300px] break-all" : ""
                  }`}
                >
                  {cell}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ExcelViewer;
