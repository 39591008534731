import Button, { variantTypes } from "components/CMS/shared/Button";
import { ValidationFieldTypes } from "components/CMS/shared/CMSConstants";
import { specificFieldValidation } from "components/CMS/shared/FieldValidations";
import { NewSelect } from "components/Form/Inputs/NewSelect";
import { NewTextField } from "components/Form/Inputs/NewTextField";
import { FormSection, Legend } from "components/Form/section";
import {
  captureSofrDetails,
  fetchSofrDetails,
} from "components/ManualActivity/redux/ManualActivitySlice";
import { sofrMonthsOptions, sofrYearOptions } from "DataMapper/SelectOptions";
import { getMonthName } from "lib/utils/dateUtils";
import { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";

const SofrDetails = () => {
  const dispatch = useDispatch();
  const { sofrDetails, isLoading } = useSelector(
    (store) => store?.manualActivityDetails
  );

  const submitSofrDetails = (values) => {
    dispatch(captureSofrDetails(values));
  };

  useEffect(() => {
    dispatch(fetchSofrDetails());
  }, []);

  return (
    <FormSection>
      <Form onSubmit={submitSofrDetails}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Legend>SOFR Details</Legend>
            <div className="mt-4 flex gap-4 justify-between text-sm">
              <p className="w-1/3 flex flex-col">
                <span className="font-semibold">Current SOFR Month:</span>
                <span>
                  {getMonthName(sofrDetails?.globalSofrDetails?.month)}
                </span>
              </p>
              <p className="w-1/3 flex flex-col">
                <span className="font-semibold">Current SOFR Year:</span>{" "}
                <span>{sofrDetails?.globalSofrDetails?.year}</span>
              </p>
              <p className="w-1/3 flex flex-col">
                <span className="font-semibold">Current SOFR value:</span>{" "}
                <span>{sofrDetails?.globalSofrDetails?.sofrValue}%</span>
              </p>
            </div>
            <div className="mt-8 flex gap-4 justify-between">
              <div className="w-1/3">
                <Field
                  name="month"
                  component={NewSelect}
                  label="Select Month"
                  options={sofrMonthsOptions}
                  validate={(v) => {
                    if (!v) return "Please select SOFR month.";
                  }}
                />
              </div>
              <div className="w-1/3">
                <Field
                  name="year"
                  component={NewSelect}
                  label="Select Year"
                  options={sofrYearOptions}
                  validate={(v) => {
                    if (!v) return "Please select SOFR year.";
                  }}
                />
              </div>
              <div className="w-1/3">
                <Field
                  name="globalSofrValue"
                  component={NewTextField}
                  label="Enter SOFR (with 5 decimals)"
                  type="number"
                  validate={(value) =>
                    specificFieldValidation(value, ValidationFieldTypes.SOFR)
                  }
                />
              </div>
            </div>
            <div className="save-button">
              <button type="submit" disabled={isLoading} onClick={handleSubmit}>
                {isLoading ? "saving..." : "Update"}
              </button>
            </div>
          </form>
        )}
      </Form>
    </FormSection>
  );
};

export default SofrDetails;
