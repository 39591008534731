import React, {useCallback} from 'react';
import {useDropzone} from 'react-dropzone';
import { fileFormatChecker } from '../../lib/utils/helper';

// import PDFJS from 'pdfjs-dist';

/**
 * @param {Function} onChange: onChange function is called when file is uplaoded
 */
function FileUpload({onChange, onError, enabled, isOnlyPdfUpload, supportedFormats, ...props}) {
  const onDrop = useCallback(acceptedFiles => {
    const file = acceptedFiles[0];
    if (!file) {
      onError({
        name: "InvalidFileException",
        message: isOnlyPdfUpload
          ? "Invalid file format. Only pdf are allowed"
          : "Invalid file format. Only jpg, png, pdf or txt are allowed",
      });
      return;
    }
    if (file?.size <= 0) {
      onError({
        name: "FileSizeException",
        message: "File size cannot be 0KB",
      });
      return;
    }
    const maxSize = 20000000
    if (file.size > maxSize) {
      onError({
        name: 'FileSizeException',
        message: 'File size should be less than 20mb',
      });
      return;
    };
    if (supportedFormats && supportedFormats?.length > 0) {
      const isFileFormatCorrect = fileFormatChecker(file?.name, supportedFormats);
      if (!file || !isFileFormatCorrect) {
        onError({
          name: "InvalidFileException",
          message: "Invalid file format",
        });
        return;
      }
    }
    // if (file.type === 'application/pdf') {
    // const reader = new FileReader();

    // reader.onabort = () => console.error('file reading was aborted');
    // reader.onerror = () => console.error('file reading has failed');
    // reader.onload = () => {
    // Do whatever you want with the file contents
    // const pdfData = reader.result;
    // PDFJS.getDocument({ data: pdfData, password: '' })
    //   .promise.then(data => {
    //     onChange(file);
    //   })
    //   .catch(e => {
    //     onError({ name: e.name, message: e.message, file });
    //   });
    // };
    // reader.readAsBinaryString(file);
    // } else {
    onChange(file);
    // }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: isOnlyPdfUpload
      ? {
          "application/pdf": [".pdf"],
        }
      : {
          "image/jpeg": [".jpg", ".jpeg"],
          "image/png": [".png"],
          "application/pdf": [".pdf"],
          "text/plain": [".txt"],
          "text/csv": [".csv"],
        },
    multiple: false,
    disabled: !enabled
  });

  return (
    <div {...getRootProps()}>
      {
        enabled &&
        <input {...getInputProps()} />
      }
      {props.children(isDragActive)}
    </div>
  );
}

export default FileUpload;
