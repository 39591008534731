import axios from "axios";
import { useState } from "react";
import { toast } from "react-toastify";
import { errorMessages, ManualCSVUploadTypes } from "../../lib/utils/constants";
import Loader from "../shared/Loader";
import { saveAs } from "file-saver";
import ManualCSVUploadForm from "./ManualCSVUploadForm";
import { manualUploadData, uploadUrls } from "./manualUploadConstants";

const ManualCSVUploads = () => {
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {};

  const saveErrorLogsForBankAndDisbursement = (response) => {
    let csvString = "";
    const header = Object.keys(response?.data?.data[0]).join(",");
    const values = response?.data?.data
      .map((o) => Object.values(o).join(","))
      .join("\n");
    csvString += header + "\n" + values;
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    saveAs(url, "Failed upload data");
  };

  const saveErrorLogsForRefundAndCashback = (response, uploadType) => {
    let csvString = "";
    const header =
      uploadType === ManualCSVUploadTypes.REFUND
        ? "Correlation_ID, Refund upload status"
        : "App_ID, Cashback Upload Status";
    const uploadedIds = Object.keys(response?.data?.data);
    const values = uploadedIds
      ?.map((id) => id + "," + response?.data?.data[id])
      .join("\n");
    csvString += header + "\n" + values;
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    saveAs(url, "Upload data status");
  };

  const handleUpload = async (file, uploadType) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await axios.request({
        method: "POST",
        url: uploadUrls[uploadType],
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (!response?.data?.success) {
        toast.error(
          response?.data?.message || errorMessages?.SOMETHING_WENT_WRONG
        );
      } else {
        if (
          [
            ManualCSVUploadTypes.DISBURSEMENT,
            ManualCSVUploadTypes.BANKSTATUS,
          ].includes(uploadType) &&
          response?.data?.data?.length > 0
        ) {
          saveErrorLogsForBankAndDisbursement(response);
        } else if (
          uploadType === ManualCSVUploadTypes.REFUND ||
          uploadType === ManualCSVUploadTypes.CASHBACK
        ) {
          saveErrorLogsForRefundAndCashback(response, uploadType);
        }
        toast.success("Uploaded data!");
      }
    } catch (error) {
      console.error(error);
      toast.error(errorMessages?.FAILED_TO_FETCH);
    } finally {
      setLoading(false);
    }
  };

  return loading ? (
    <Loader />
  ) : (
    manualUploadData?.map((uploadDetails, index) => (
      <ManualCSVUploadForm
        key={`${uploadDetails?.sectionTitle}-${index}`}
        {...uploadDetails}
        handleSubmit={handleSubmit}
        handleUpload={handleUpload}
      />
    ))
  );
};
export default ManualCSVUploads;
