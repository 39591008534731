import { Field, Form } from "react-final-form";
import { Upload } from "../Form/Inputs/Upload";
import { FormSection, Legend, Section } from "../Form/section";

const ManualCSVUploadForm = ({
  handleSubmit,
  handleUpload,
  sectionTitle,
  fieldName,
  fieldLabel,
  uploadType,
}) => {
  return (
    <FormSection>
      <Form onSubmit={handleSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Section>
              <Legend>{sectionTitle}</Legend>
              <div className="mt-8">
                <Field
                  name={fieldName}
                  component={Upload}
                  label={fieldLabel}
                  uploadFile={(file) => {
                    handleUpload(file, uploadType);
                  }}
                  supportedFormats={["csv"]}
                />
              </div>
            </Section>
          </form>
        )}
      </Form>
    </FormSection>
  );
};
export default ManualCSVUploadForm;
