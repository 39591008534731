import { Tab, Tabs } from "@mui/material";
import {
  TabPanel,
  useStyles,
} from "components/DisbursementApplicationLeadDetails/DisbursementTabPanelComp";
import GlobalUpdates from "components/ManualActivity/GlobalUpdates";
import ManualCSVUploads from "components/ManualCSVUploads";
import { useState } from "react";

const ManualActivity = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const classes = useStyles();

  const handleManualActivityTabChange = (_, newValue) =>
    setActiveTabIndex(newValue);

  return (
    <div className={`${classes.root} mx-32 px-8`}>
      <Tabs
        orientation="vertical"
        value={activeTabIndex}
        onChange={handleManualActivityTabChange}
        className={classes.tabs}
      >
        <Tab label="CSV Uploads" />
        <Tab label="Global Updates" />
      </Tabs>
      <TabPanel value={activeTabIndex} index={0}>
        <ManualCSVUploads />
      </TabPanel>
      <TabPanel value={activeTabIndex} index={1}>
        <GlobalUpdates />
      </TabPanel>
    </div>
  );
};

export default ManualActivity;
