import Spinner from "components/common/Spinner";
import FileUpload from "../../FileUpload";
import FileViewModal from "../../../DocumentsSection/FileViewModal";
import useUploadService from "components/Form/Inputs/Upload/useUploadService";

const ctaZeroFilesText = "Select file";
const ctaUploadedFilesText = "Replace file";

const Upload = ({
  input,
  meta,
  label,
  uploadFile = () => {},
  disabled,
  isOnlyPdfUpload,
  forBankAccount = false,
  slug = null,
  supportedFormats = [],
  isView,
  helperText,
}) => {
  const {
    error,
    loading,
    password,
    viewFileModal,
    blobUrl,
    fileType,
    handleErrors,
    handleFileUpload,
    handleView,
    closeViewModal,
  } = useUploadService({
    label,
    input,
    uploadFile,
  });

  const ctaText = input.value ? ctaUploadedFilesText : ctaZeroFilesText;
  const inputValue = { ...input?.value[0] };

  return (
    <div>
      <FileUpload
        key={`${input.value}-${label}`}
        enabled={!disabled}
        isOnlyPdfUpload={isOnlyPdfUpload}
        forBankAccount={forBankAccount}
        label={label}
        onChange={handleFileUpload}
        onError={handleErrors}
        supportedFormats={supportedFormats}
      >
        {(isDragActive) => {
          return (
            <div
              className={`file-upload-box ${isDragActive && "bg-gray-300"} ${
                (meta.touched && meta.error) || error
                  ? "border-red-600"
                  : "border-gray-400"
              }`}
            >
              <div className="flex justify-between items-center">
                <div
                  className={`file-upload-label ${
                    (input.value[0] && input.value[0].id && "active") || true
                  } ${loading && "loading"} ${
                    ((meta.touched && meta.error) || error) && "error"
                  }`}
                >
                  {loading && (
                    <div className="spinner-holder">
                      <Spinner borderSize={0.1} size={1.1} />
                    </div>
                  )}
                  {label}
                </div>
                <div className="upload-right-section flex items-center whitespace-nowrap">
                  {input.value[0] && (
                    <div className="filename">{input.value[0].name}</div>
                  )}
                  {isView && input.value[0] && (
                    <div
                      className="btn-info mr-2 file-upload-cta hover:bg-[#31b0d5] hover:text-white"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleView({
                          forBankAccount,
                          slug,
                          password: inputValue?.password,
                        });
                      }}
                    >
                      View file
                    </div>
                  )}
                  {!disabled && (
                    <div className="file-upload-cta">{ctaText}</div>
                  )}
                </div>
              </div>
            </div>
          );
        }}
      </FileUpload>
      {viewFileModal && (
        <FileViewModal
          open={viewFileModal}
          blobUrl={blobUrl}
          password={password}
          fileType={fileType}
          onClose={closeViewModal}
          onCancel={closeViewModal}
        />
      )}
      {meta.touched && meta.error && (
        <p className="text-xs text-red-600">{meta.error}</p>
      )}
      {error && <p className="text-xs text-red-600">{error}</p>}
      {helperText && <p className="text-xs text-gray-200 my-1">{helperText}</p>}
      <style jsx>{styleTag}</style>
    </div>
  );
};

export { Upload };

export const styleTag = `
.file-upload-box {
  width: 100%;
  background: #ffffff;
  border: 1px dashed #dadbdd;
  box-sizing: border-box;
  border-radius: 6px;
  padding: 12px;
  cursor: pointer;
}

.file-upload-label {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #565660;
  display: inline-block;
  margin-top: 3px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
}

.multiple-filename-container .file-upload-label {
  font-size: 12px;
  color: #565660;
}

.file-upload-label.active {
  color: #00b0aa;
}

.file-upload-label.loading {
  color: #00b0aa;
}

.file-upload-label.error {
  color: #ff695b;
}

.file-upload-label::before {
  content: url("/assets/icons/upload.svg");
  vertical-align: middle;
  margin-right: 13px;
}

.file-upload-label.active::before {
  color: #00b0aa;
  content: url("/assets/icons/tick.svg");
  vertical-align: sub;
}

.file-upload-label.error::before {
  color: #ff695b;
  content: url("/assets/icons/red-exclamation.svg");
  vertical-align: sub;
}

.file-upload-label.multiple::before {
  color: #84848c;
  content: url("/assets/icons/gray-document.svg");
  vertical-align: bottom;
}

.multiple-filename-container {
  border-top-width: 1px;
  border-top-color: rgba(218, 219, 221, 0.6);
}

.file-upload-label.loading::before {
  content: "";
  margin-right: 0;
}

.spinner-holder {
  margin-right: 15px;
  display: inline-block;
}

.spinner-holder svg {
  stroke: #00b0aa;
}

.file-upload-cta {
  background: #f5f8f9;
  border-radius: 4px;
  padding: 4px 10px;
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  text-align: right;
  color: #009df6;
}

.multiple-filename-container .file-upload-cta {
  background: none;
}

.filename {
  display: inline-block;
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #828282;
  max-width: 200px;
  margin-right: 15px;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.file-upload-box.border-red-600 {
  border-color: #ff695b;
}

.multiple-filename-container {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  color: #565660;
}

@screen sm {
  .filename {
    display: inline-block;
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: right;
  }

  .file-upload-label {
    max-width: 300px;
  }

  .multiple-filename-container .file-upload-label {
    max-width: 500px;
  }
}
`;
